import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'

// Assets
import Loading from '../../../assets/gif/loading.gif'

// graphQL
import { getGraphComicsCards, getGraphGenres } from '../../../graphQL'

// Utils
import { createPath, shuffleArray } from '../../../utils/utils'

// Model
import { ComicModel, GenreModel } from '../../../models'

// Components
import Carrousel from '../../organisms/Carrousel'

// Style
import {
  homeTitle,
  homeGenre,
  homeSeeAll,
  homeLoading,
  homeLoadingIcon,
} from './style.module.scss'

type GruopedComics = {
  genre: GenreModel
  comics: ComicModel[]
}

const HomeTemplate = () => {
  const allComics = getGraphComicsCards()
  const genres = getGraphGenres()

  const [grouped, setGrouped] = useState<GruopedComics[]>([])

  const groupedComics: GruopedComics[] = []
  genres.forEach((genre) => {
    const comics = allComics.filter(
      (comic) =>
        comic.genres.some((g) => genre.id === String(g.id)) && comic.cover
    )
    groupedComics.push({ genre, comics: shuffleArray(comics) })
  })

  groupedComics.sort((a, b) => b.comics.length - a.comics.length)

  useEffect(() => {
    setGrouped(groupedComics)
  }, [])

  return (
    <div style={{marginBottom:'32px'}}>
      {grouped.length > 0 ? (
        grouped.map(
          (group) =>
            group.comics.length > 4 && (
              <div key={`home_${group.genre.name}_carrousel`}>
                <h3 className={homeGenre}>
                  <span className={homeTitle}>
                    {group.genre.name} ({group.comics.length})
                  </span>
                  <span
                    className={homeSeeAll}
                    onClick={() => {
                      navigate(
                        `/generos/${group.genre.id}-${createPath(
                          group.genre.name
                        )}`
                      )
                    }}
                  >
                    Ver todos
                  </span>
                </h3>
                <Carrousel comics={group.comics} genre={group.genre} />
              </div>
            )
        )
      ) : (
        <div className={homeLoading}>
          <img
            width={48}
            height={48}
            src={Loading}
            className={homeLoadingIcon}
          />
          <h1>Cargando ...</h1>
        </div>
      )}
    </div>
  )
}

export default HomeTemplate
